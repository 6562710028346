.container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.alert {
  background-color: rgb(209, 236, 241) !important;
  color: #0c5460 !important;
  padding: 0.1rem 1rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.contentBox {
  display: flex;
  margin-top: 1.5rem;
}

.navigation {
  width: 40rem;
  margin-right: 1.5rem;
  position: sticky;
  top: 1.5rem;
  height: min-content;
}

.content {
  > * {
    margin-bottom: 1.5rem;
  }

  code {
    white-space: pre-wrap;
  }
}

.overview {
  img {
    width: 100%;
  }
}

.authentication {
  img {
    width: 100%;
  }
}

.usage {
  code {
    color: #e83e8c;
    font-size: 0.9rem;
  }

  pre > code {
    color: inherit;
  }

  img {
    max-width: 100%;
  }
}

.examples {
  h4 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 1.3rem;
  }

  pre {
    margin: 0;
  }
}

@media (max-width: 600px) {
  .contentBox {
    flex-direction: column;
  }

  .navigation {
    width: 100%;
    margin-bottom: 1.5rem;
    position: initial;
  }
}
