.navButton {
  justify-content: flex-start !important;
  text-transform: none !important;
  font-size: 1.2rem !important;
  padding: 0.6rem 1.2rem !important;
}

.active {
  color: white !important;
  background-color: #007bff !important;

  :hover {
    color: white !important;
    background-color: #007bff !important;
  }
}
