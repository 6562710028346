.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;

  img {
    width: 15rem;
    margin-top: 3rem;
  }

  h1 {
    margin-top: 2rem;
    margin-bottom: 0;
    z-index: 1;
    font-size: 3.5rem;
    font-weight: 400;
  }

  p {
    font-size: 1.3rem;
  }
}
