.changeLog {
  padding-top: 1rem;

  h4 {
    margin: 0;
    font-weight: 400;
  }

  code {
    color: #e83e8c;
    font-size: 0.9rem;
  }
}

.loading,
.error {
  display: flex;
  padding-top: 1rem;
}
