html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #212529;
}

h2 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400 !important;
}

a {
  color: #007bff;
  text-decoration: none;
}

p,
li {
  line-height: 1.5rem;
}

code {
  line-height: 1.2rem;
}
